import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <a href="#content" className="sr-only focusable">
          Direkt zum Seiteninhalt
        </a>
        <div id="top" className="page-wrapper">
          <Header></Header>
          <main id="content" className="page-content">
            <ul className="breadcrumb">
              <li className="breadcrumb__list-item">
                <a href="/" className="breadcrumb__link">
                  Home
                </a>
              </li>
              <li className="breadcrumb__list-item">
                <span className="breadcrumb__text">&nbsp;Cookie-Informationen</span>
              </li>
            </ul>
            <h1 className="page-headline">Cookie-Richtlinie</h1>
            <div className="margin-module">
              <div className="margin-module__main-column">
                <div className="text-module">
                  <p>
                    Diese Cookie-Richtlinie verfolgt den Zweck, Sie über unsere
                    Vorgehensweisen in Bezug auf Ihre Daten aufzuklären, die wir
                    mithilfe von Cookies und anderen Tracking-Technologien
                    erfassen (z.B. Gifs, Web Beacons, etc.)
                  </p>
                  <h4 className="h4">
                    Die Funktionalitäten, für die wir diese Technologien
                    einsetzen, können Folgendes beinhalten:
                  </h4>
                  <ul>
                    <li>Sie in der Navigation zu unterstützen,</li>
                    <li>
                      Sie dabei zu unterstützen, sich für unsere Veranstaltungen
                      zu registrieren, beim Einloggen und bei Ihren
                      Möglichkeiten, uns Feedback zu geben,
                    </li>
                    <li>
                      die Verwendung unserer Produkte, Dienstleistungen oder
                      Anwendungen zu analysieren,
                    </li>
                    <li>
                      Sie mit unseren Marketing-Aktivitäten zu unterstützen
                      (incl. interessensangepasste Werbung),
                    </li>
                    <li>
                      Inhalte von Dritten anzubieten (z.B. Social Media
                      Inhalte).
                    </li>
                  </ul>
                  <h4 className="h4">
                    Am Ende dieser Richtlinie finden Sie eine detaillierte
                    Auflistung der Cookies mit Beschreibung. Wir klassifizieren
                    Cookies entsprechend den nachfolgenden Kategorien:
                  </h4>
                  <ul>
                    <li>Unbedingt erforderliche Cookies</li>
                    <li>Leistungs-Cookies</li>
                    <li>Funktionelle Cookies</li>
                    <li>Targeting Cookies</li>
                  </ul>
                  <p>
                    Wir setzen Cookies nur, wenn das gültige Recht es
                    ausdrücklich erlaubt oder nachdem wir Ihr Einverständnis
                    hierfür über das Cookie-Banner oder über die Einstellungen
                    erhalten haben. Sie können Ihre Cookie Einstellungen für
                    jede Kategorie zu jeder Zeit verändern, indem Sie auf den
                    Cookie-Einstellungen-Button weiter unten klicken (außer für
                    die Kategorie unbedingt erforderliche Cookies, da diese zum
                    Funktionieren der Website notwendig sind):
                  </p>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                                Cookie-Einstellungen
                  </button>
                  <p>&nbsp;</p>
                  <div id="ot-sdk-cookie-policy">&nbsp;</div>
                  <h2 className="h2">Links zu anderen Websites</h2>
                  <p>
                    Diese Website kann Links oder Querverweise zu anderen
                    Websites enthalten. Bitte berücksichtigen Sie, dass wir die
                    Cookies- / Tracking-Technologien anderer Websites nicht
                    kontrollieren und diese Cookie-Richtlinie nicht für solche
                    Websites gilt.
                  </p>
                  <h2 className="h2">Kontaktaufnahme</h2>
                  <p>
                    Wenn Sie Fragen, Kommentare oder Bedenken bezüglich dieser
                    Cookie-Richtlinie oder der Informationspraktiken dieser
                    Website haben, nehmen Sie mit uns bitte Kontakt über die in
                    der Datenschutzerklärung angegebenen Kontaktinformationen
                    auf.
                  </p>
                  <h2 className="h2">Änderungen dieser Cookie-Richtlinie</h2>
                  <p>
                    Bei Änderungen dieser Cookie-Richtlinie wird die
                    überarbeitete Richtlinie auf dieser Website bekannt gegeben.
                    Diese Cookie-Richtlinie wurde zuletzt am 27.04.2018
                    aktualisiert.
                  </p>
                </div>
              </div>
              <div className="margin-module__margin-column">
                <div className="image-module">
                  <img
                    src="/img/content/johnson-johnson-austria.jpg"
                    className="image-module__image"
                    alt="Kenvue Austria"
                    height="196"
                    width="300"
                  />
                </div>
                <div className="text-module">
                  <h3 className="h3">
                    Standort Österreich
                    <br />
                    Johnson&nbsp;&amp;&nbsp;Johnson GmbH
                  </h3>
                  <p>
                    Vorgartenstraße 206B
                    <br />
                    A-1020 Wien
                    <br />
                    Tel: <a>+43(0) 1 725 / 15 - 0</a>
                    <br />
                    Fax: <a>+43(0) 1 725 / 15 - 9415</a>
                  </p>
                </div>
              </div>
            </div>
          </main>
          <Footer></Footer>
        </div>
        <script src="/js/main.js" />
        
        <script src="https://www.google-analytics.com/analytics.js" async />
      </div>
    );
  }
}

export default Page;
